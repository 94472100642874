import React from 'react';

const CompanyInfo = () => (
    <div className="col-md-4 info-section">
        <h5>Company Information</h5>
        <p>Address: Yuxi Industrial Park, Sanshui, Foshan, Guangdong, China</p>
    </div>
);

const ContactDetails = () => (
    <div className="col-md-4 info-section">
        <h5>Contact Information</h5>
        <p>Phone: +86 13450853338</p>
        <p>Email: sales@xfs.com</p>
        <p>Business Hours: Monday to Friday, 9:00 AM - 6:00 PM</p>
    </div>
);

const SocialMedia = () => (
    <div className="col-md-4 info-section">
        <h5>Follow Us</h5>
        <ul className="social-media-list">
            <li>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <img src="http://karachemicals.com/wp-content/uploads/2024/01/instagramicon.png" alt="Instagram" className="social-icon" /> Instagram
                </a>
            </li>
            <li>
                <a href="https://ir.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <img src="http://karachemicals.com/wp-content/uploads/2024/01/linkedin-icon.png" alt="LinkedIn" className="social-icon" /> LinkedIn
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                    <img src="http://karachemicals.com/wp-content/uploads/2024/01/facebook.png" alt="Facebook" className="social-icon" /> Facebook
                </a>
            </li>
        </ul>
    </div>
);


const Map = () => (
    <div className="col-md-12 info-section">
        <h5>Location</h5>
        <div className="map-container">
            <iframe
                title="Company Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3666.4936656259574!2d112.92523785526427!3d23.225116259669587!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34028beb80db44ff%3A0xecfd7bf5cce7b85f!2z5LiJ5rC055-z5rmW5rSy6KOV5pum5bel5Lia5Zut!5e0!3m2!1sen!2s!4v1720273588704!5m2!1sen!2s"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
            ></iframe>
        </div>
    </div>
);

const ContactInfo = () => (
    <div className="contact-info container">
        <div className="row">
            <CompanyInfo />
            <ContactDetails />
            <SocialMedia />
        </div>
        <div className="row">
            <Map />
        </div>
    </div>
);

export default ContactInfo;
