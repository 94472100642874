import React from 'react';

const faqs = [
    {
        id: 1,
        question: 'Question 1: How can I contact customer service?',
        answer: 'Answer 1: You can reach us by calling 123-456-7890 or by emailing us at info@company.com.'
    },
    {
        id: 2,
        question: 'Question 2: Where is the company located?',
        answer: 'Answer 2: The company is located at 123 Some Street.'
    },
    {
        id: 3,
        question: 'Question 3: What is the return policy?',
        answer: 'Answer 3: Our return policy allows returns within 30 days of purchase. Please see our return policy page for more details.'
    },
    {
        id: 4,
        question: 'Question 4: What are your business hours?',
        answer: 'Answer 4: Our business hours are from 9 AM to 6 PM, Monday to Friday.'
    },
    {
        id: 5,
        question: 'Question 5: Do you offer international shipping?',
        answer: 'Answer 5: Yes, we offer international shipping. Shipping fees and delivery times vary by location.'
    },
    {
        id: 6,
        question: 'Question 6: How can I track my order?',
        answer: 'Answer 6: You can track your order using the tracking number provided in your order confirmation email.'
    },
    // Add more FAQs as needed
];

const FAQ = () => {
    return (
        <div className="container my-5">
            <h2 className="text-center mb-4">Frequently Asked Questions</h2>
            <div className="accordion" id="faqAccordion">
                {faqs.map((faq, index) => (
                    <div className="accordion-item" key={faq.id}>
                        <h2 className="accordion-header" id={`heading${faq.id}`}>
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${faq.id}`}
                                aria-expanded={index === 0 ? "true" : "false"}
                                aria-controls={`collapse${faq.id}`}
                            >
                                {faq.question}
                            </button>
                        </h2>
                        <div
                            id={`collapse${faq.id}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                            aria-labelledby={`heading${faq.id}`}
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                {faq.answer}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
