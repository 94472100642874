import React from 'react';

const products = [
    { id: 1, name: 'Product 1', imageUrl: './images/product_1.jpg', description: 'High-quality plastic pellets ideal for cable sheaths.' },
    { id: 2, name: 'Product 2', imageUrl: './images/product_2.jpg', description: 'Durable plastic pellets suitable for water pipes.' },
    { id: 3, name: 'Product 3', imageUrl: './images/product_3.jpg', description: 'Versatile plastic pellets perfect for lighting fixtures.' },
    { id: 4, name: 'Product 4', imageUrl: './images/product_4.jpg', description: 'Colorful plastic pellets designed for toys.' },
    { id: 5, name: 'Product 5', imageUrl: './images/product_5.jpg', description: 'Cost-effective plastic pellets for small appliances.' },
    { id: 6, name: 'Product 6', imageUrl: './images/product_6.jpg', description: 'High-performance plastic pellets for industrial use.' },
    { id: 7, name: 'Product 7', imageUrl: './images/product_7.jpg', description: 'Eco-friendly plastic pellets for sustainable products.' },
    { id: 8, name: 'Product 8', imageUrl: './images/product_1.jpg', description: 'Premium plastic pellets for custom applications.' },
    // Add more products
];

const ProductDisplay = () => {
    return (
        <div className="container my-5">
            <h2 className="text-center mb-4">Product Showcase</h2>
            <div className="row g-4">
                {products.map(product => (
                    <div key={product.id} className="col-md-4">
                        <div className="card">
                            <img src={product.imageUrl} className="card-img-top" alt={product.name} />
                            <div className="card-body">
                                <h5 className="card-title">{product.name}</h5>
                                <p className="card-text">{product.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductDisplay;
