import React from 'react';

const text = 'XiangFaShun, located in Guangdong Province, China, is a professional plastic granulate manufacturer. Our products are widely used in cable sheathing, water pipes, lighting fixtures, toys, small appliances, and other fields. We can produce plastic pellets in various colours and provide raw materials with better quality and lower cost.';

const CompanyIntro = () => {
    return (
        <div className="container my-5">
            <h2 className="text-center mb-4">Company Introduction</h2>
            <p className="text-center">{text}</p>
        </div>
    );
};

export default CompanyIntro;
