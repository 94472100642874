import React from 'react';
import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Navbar from './models/navbar.js'
import Carousel from './models/carousel.js'
import CompanyIntro from './models/companyIntro.js'
import ProductDisplay from './models/productdisplay.js'
import CustomerReviews from './models/customerreviews.js'
import FAQ from './models/faq.js'
import ContactInfo from './models/contactinfo.js'

function App() {
  return (
    <div >
      <Navbar />
      <Carousel />
      <CompanyIntro />
      <ProductDisplay />
      <CustomerReviews />
      <FAQ />
      <ContactInfo />
    </div>
  );
}

export default App;
