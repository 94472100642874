import React from 'react';

const reviews = [
    {
        id: 1,
        name: 'Customer 1',
        content: 'This is an excellent company with top-notch product quality and great service.',
        rating: 5
    },
    {
        id: 2,
        name: 'Customer 2',
        content: 'The customer service is very friendly and resolved all my issues. Highly satisfied.',
        rating: 4
    },
    {
        id: 3,
        name: 'Customer 3',
        content: 'Fast shipping and well-packaged products. Will definitely purchase again.',
        rating: 5
    },
    // Add more customer reviews
];

const CustomerReviews = () => {
    return (
        <div className="container my-5">
            <h2 className="text-center mb-4">Customer Reviews</h2>
            <div className="row">
                {reviews.map(review => (
                    <div key={review.id} className="col-md-4 mb-4">
                        <div className="review-card">
                            <div className="card-body">
                                <h5 className="card-title">{review.name}</h5>
                                <p className="card-text">{review.content}</p>
                                <p className="card-text">Rating: {review.rating} / 5</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomerReviews;
